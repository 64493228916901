import React from 'react'
import { CustomCard } from '../components/CustomCardHome/CustomCard'
import { Customtabs } from '../components/CustomTabsHome/Customtabs'
import { CardCrousel } from '../components/CardCarousel/CardCrousel'
import { Footer } from '../components/Footer/Footer'
import { MainCarousel } from '../components/Carousel/MainCarousel'
export const Home = () => {
  return (
    <>
    <CustomCard/>
    <div>
     {/* <MainCarousel/> */}
    </div>
    <div>
    {/* <Customtabs/> */}
    </div>
    <div>
    <CardCrousel/>
    </div>
    <div>
    <Footer/>
    </div>
    </>
  )
}
