import React from 'react'
import vectorImg1 from '../../media/img1.png'
import { BoxAnimation } from '../BoxAnimation/BoxAnimation'
import { Navbar } from '../Header/Navbar'


export const CustomCard = () => {
  return (
    <>

  <div className="container-fluid   cardBg" >
    <Navbar/>
        <div className='col-md-11 mx-auto'>
        <div className="row">
          <div className="col-md-6 text-light">
            <div className="card border-0 bg-transparent mx-auto" style={{ marginTop:'10rem',marginBottom:'6rem' }}>
              <div className="card-body">
                <h1 className="card-title animate-reveal animate-first MainHeadings ">
                Here 'The Good' Is Better Then The Best
                </h1>
                
                <p className="card-text animate-reveal animate-second SubHeadings  mt-4">
                We are a fast growing team of digital 
                experts with more than a decade experience in building mobile applications, websites and e-commerce solutions
                 across the globe. Our undivided passion and focus on latest technologies makes us the right people to deliver your digital needs. We provide niche staffing,
                consulting, implementation and support services to our clients globally.
                </p>

            </div>
            </div>  
          </div>
          <div className="col-md-6">
          <div className="card  border-0 bg-transparent" style={{marginTop:'10rem' , marginBottom:'6rem'}}>
            <div className='col-md-10 mx-auto floating'>
            <img src={vectorImg1} className="card-img-top animate-reveal animate-first" alt="..."/>
            </div>
          </div>
          </div>
        </div>
        </div>

        <BoxAnimation/>
</div>
    </>
  )
}
