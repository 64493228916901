import React from "react";
import { Navbar } from "../Header/Navbar";
import protlabs from "../../media/proTlabs1.jpg";
import slidernew from "../../media/slider-new.jpg";
import { Footer } from "../Footer/Footer";
import proTLabsImg from "../../media/ProTLabs.png";
import { BoxAnimation } from "../BoxAnimation/BoxAnimation";

export const Prolabs = () => {
  return (
    <>
      <div className="container-fluid card-bg">
        <Navbar />
        <div className="row">
          <div className="col-md-6">
            <div
              className="card bg-transparent border-0 animate-reveal animate-first"
              style={{ marginTop: "8rem", marginBottom: "6rem" }}
            >
              <div className="card-body" style={{ marginTop: "16rem" }}>
                <h1 className="MainHeadings ">&copy; Pro (t) Labs</h1>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="card  border-0 bg-transparent"
              style={{ marginTop: "10rem", marginBottom: "6rem" }}
            >
              <div className="col-md-10 mx-auto floating">
                <img
                  src={proTLabsImg}
                  className="card-img-top animate-reveal animate-first"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-10 mx-auto animate-reveal animate-first">
        <div className="row row-cols-1 row-cols-md-2 g-4 mt-5">
          <div className="col">
            <div className="card cardStyle">
              <img src={protlabs} />
              <div className="info">
                <h1>CLIENT CENTRIC SERVICE MODELSs</h1>
              </div>
            </div>
            <div className="mt-5">
              <h2 className="InnerMianHaddings">
                <strong>CLIENT CENTRIC SERVICE MODELSs</strong>
              </h2>

              <p className="SubHeadingsBlack">
                IT has evolved and so did the Industries but do we have scope
                for our IT Services to be further evolved? How often do the IT
                service providers realize that they are still offering age old
                service models to their clients!
              </p>

              <p className="SubHeadingsBlack">
                ©Pro(T) Labs is continuously building newer client centric
                service model focusing on client benefits which can bring at
                least 20%-30% direct cost savings and further 5-10% indirect
                cost savings while ensuring better quality and shortened
                delivery timelines.
              </p>
            </div>
          </div>
          <div className="col">
            <div className="card cardStyle">
              <img src={slidernew} />
              <div className="info">
                <h1>SERVICE PRODUCTS</h1>
              </div>
            </div>
            <div className="mt-5 ">
              <h2 className="InnerMianHaddings">
                <strong>SERVICE PRODUCTS</strong>
              </h2>

              <p className="SubHeadingsBlack">
                IT has evolved and so did the Industries but do we have scope
                for our IT Services to be further evolved? How often do the IT
                service providers realize that they are still offering age old
                service models to their clients!
              </p>

              <p className="SubHeadingsBlack">
                ©Pro(T) Labs is continuously building newer client centric
                service model focusing on client benefits which can bring at
                least 20%-30% direct cost savings and further 5-10% indirect
                cost savings while ensuring better quality and shortened
                delivery timelines.
              </p>
            </div>
          </div>
        </div>
      </div>

      <BoxAnimation />
      <div className="mt-5">
        <Footer />
      </div>
    </>
  );
};
