import React, { useEffect, useRef } from "react";
import { Navbar } from "../Header/Navbar";
import { Footer } from "../Footer/Footer";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Modal } from "reactstrap";
import sendGif from "../../media/sendGif.gif";
import { BoxAnimation } from "../BoxAnimation/BoxAnimation";
import LoginService from "../../services/LoginService/LoginServices";
import { toast } from "react-toastify";

export const DeleteUser = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [modal, setModal] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [data, setData] = useState({});
  const [inputOTP, setInputOTP] = useState("");

  const submit = async (data) => {
    setData(data);
    await LoginService.checkUserExists(data?.number)
      .then((response) => {
        if (response?.data?.errorCode === -101) {
          setShowButton(false);
          const successToast = toast.error(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        } else {
          setShowButton(true);

          LoginService.sendOtpForDeactivation(data?.number)
            .then((response) => {
              const successToast = toast.success(`${response?.data?.message}`, {
                position: "bottom-left",
                autoClose: false,
              });
              setTimeout(() => {
                toast.dismiss(successToast);
              }, 2000);
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verifyOTP = () => {
    if (inputOTP) {
      LoginService.OTPVerficationSignUp("otp", data?.number, inputOTP)
        .then((response) => {
          if (response?.data?.errorCode === -100) {
            const successToast = toast.error(response?.data?.message, {
              position: "bottom-left",
              autoClose: false,
            });
            setTimeout(() => {
              toast.dismiss(successToast);
            }, 2000);
          } else {
            const successToast = toast.success(
              `${response?.data?.message}! Successfully Requested`,
              {
                position: "bottom-left",
                autoClose: false,
              }
            );
            setTimeout(() => {
              toast.dismiss(successToast);
            }, 2000);
            setShowButton(false);
            setInputOTP("");
            setData({});
            reset();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div className="container-fluid card-bg">
        <Navbar />
        <div className="row delete-card">
          <div className="col-md-6">
            <div
              className="card bg-transparent border-0 animate-reveal animate-first"
              style={{ marginTop: "6rem", marginBottom: "4rem" }}
            >
              <div className="card-body" style={{ marginTop: "1rem" }}>
                <h1 className="MainHeadings">Delete Request</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-10 mt-5 mx-auto animate-reveal animate-first">
        <div className="card Formbg bg-transparent">
          <div className="card border-0 p-0 text-center  formHaderBG ">
            <div className="card-body SubHeadings">
              <h3 className="card-title">
                <strong>Delete Account Request</strong>
              </h3>
              <p className="card-text">
                <strong>
                  We would love to hear from you . Please drop us a line and we
                  will get back to you at the earliest.
                </strong>
              </p>
            </div>
          </div>

          <form
            className="row g-3 p-4 SubHeadingsBlack"
            onSubmit={handleSubmit(submit)}
          >
            <div className="col-md-6 row">
              <div className="col-md-6">
                <label for="inputEmail4" className="form-label">
                  Mobile Number
                </label>
                <input
                  type="text"
                  className="form-control bg-transparent text-dark"
                  required
                  maxlength="10"
                  id="inputEmail4"
                  {...register("number", {
                    required: true,
                  })}
                />
              </div>

              <div className="col-md-6">
                <button type="submit" className="btn btn-warning mt-4">
                  Submit
                </button>
              </div>
            </div>
            {showButton && (
              <>
                <div className="col-12 mt-4">
                  <label
                    for="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    Why do you want to deactivate your Account?(optional)
                    <sup>*</sup>
                  </label>
                  <textarea
                    className="form-control bg-transparent text-dark"
                    id="exampleFormControlTextarea1"
                    rows="4"
                    {...register("message")}
                  ></textarea>
                </div>
                <div className="col-md-6 ">
                  <label className="form-label">Enter Otp</label>
                  <input
                    type="text"
                    inputmode="numeric"
                    pattern="\d*"
                    className="form-control bg-transparent text-dark"
                    required
                    maxlength="6"
                    onChange={(e) => setInputOTP(e.target.value)}
                  />
                </div>
                <div className="col-12">
                  <button
                    type="button"
                    onClick={() => verifyOTP()}
                    className="btn btn-success"
                  >
                    Verify Otp
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </div>

      <BoxAnimation />
      <div className="mt-5">
        <Footer />
      </div>

      <Modal
        className="position-absolute top-50 start-50 translate-middle"
        size={200}
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <div className="card bg-transparent border-0">
          <div className="card-body">
            <img src={sendGif} alt="Sendgif" width={200} height={150} />
          </div>
        </div>
      </Modal>
    </>
  );
};
