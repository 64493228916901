import React from 'react'
import { MissionValues } from '../components/MissionValues/MissionValues'
import { Footer } from '../components/Footer/Footer'
export const Mission = () => {
  return (
    <>
      <div>
        <MissionValues />
      </div>
      <div className='mt-4'>
        <Footer />
      </div>
    </>
  )
}
