import React from "react";
import { Navbar } from "../components/Header/Navbar";
import { BoxAnimation } from "../components/BoxAnimation/BoxAnimation";
import { Footer } from "../components/Footer/Footer";

export const PrivacyPolicy = () => {
  return (
    <>
      <div className="container-fluid   cardBg">
        <Navbar />
        <div className="col-md-10 mx-auto">
          <div className="card border-0 bg-transparent mx-auto">
            <div
              className="card-body"
              style={{ marginTop: "10rem", marginBottom: "6rem" }}
            >
              <h1 className="card-title animate-reveal animate-first MainHeadings text-center">
                Privacy Policy
              </h1>

              <p className="card-text animate-reveal animate-second SubHeadings  mt-4">
                <p>1. Introduction</p>

                <p></p>

                <p>
                  Welcome to Hkg Meraplace Limited Liability Partnership ,
                  FUSEIT app (&quot;we,&quot; &quot;us,&quot; or
                  &quot;our&quot;). We respect your privacy and are committed to
                  protecting your personal information. This Privacy Policy is
                  designed to help you understand how we collect, use, disclose,
                  and safeguard your personal information when you use our
                  mobile application (the &quot;App&quot;). By accessing or
                  using the App, you consent to the practices described in this
                  Privacy Policy.
                </p>

                <p></p>

                <p>2. Information We Collect</p>

                <p></p>

                <p>
                  2.1. Information You Provide: When you use the App, you may be
                  required to provide certain personal information, including
                  but not limited to your name, email address, username, and
                  other information you voluntarily provide when using the App.
                </p>

                <p></p>

                <p>
                  2.2. Automatically Collected Information: We may collect
                  certain information automatically, such as your device type,
                  operating system, unique device identifiers, IP address, and
                  usage information. This information helps us improve the App
                  and understand how users interact with it.
                </p>

                <p></p>

                <p>3. How We Use Your Information</p>

                <p></p>

                <p>
                  We use the information we collect for various purposes,
                  including:
                </p>

                <p></p>

                <p>
                  3.1. Providing and improving the App's functionality and
                  features.
                </p>

                <p></p>

                <p>3.2. Personalizing your experience within the App.</p>

                <p></p>

                <p>
                  3.3. Communicating with you about updates, news, and
                  promotions related to the App.
                </p>

                <p></p>

                <p>3.4. Analyzing usage patterns to enhance our services.</p>

                <p></p>

                <p>3.5. Complying with legal obligations.</p>

                <p></p>

                <p>
                  4. Sharing Your Information with the parties you collaborate
                  with.
                </p>

                <p></p>

                <p>
                  We do not sell, rent, or trade your personal information to
                  third parties. However, we may share your information with:
                </p>

                <p></p>

                <p>
                  4.1. Service Providers: Third-party service providers who help
                  us operate the App, such as hosting, analytics, and customer
                  support services.
                </p>

                <p></p>

                <p>
                  4.2. Legal Compliance: When required by law or to protect our
                  rights, privacy, safety, or property.
                </p>

                <p></p>

                <p>5. Your Choices</p>

                <p></p>

                <p>
                  You have choices regarding the personal information you
                  provide:
                </p>

                <p></p>

                <p>
                  5.1. Access and Correction: You may access and correct your
                  personal information through the App's settings.
                </p>

                <p></p>

                <p>
                  5.2. Opt-Out: You can opt out of receiving promotional
                  communications from us by following the instructions provided
                  in those communications.
                </p>

                <p></p>

                <p>6. Data Security</p>

                <p></p>

                <p>
                  We take reasonable steps to protect your personal information
                  from unauthorized access or disclosure. However, no method of
                  transmission over the Internet or electronic storage is 100%
                  secure. We cannot guarantee absolute security.
                </p>

                <p></p>

                <p>7. Children's Privacy</p>

                <p></p>

                <p>
                  The App is not intended for use by individuals under the age
                  of 13. If you believe we have inadvertently collected
                  information from a child under 13, please contact us to
                  request its removal.
                </p>

                <p></p>

                <p>8. Changes to this Privacy Policy</p>

                <p></p>

                <p>
                  We reserve the right to update or modify this Privacy Policy
                  at any time. We will notify you of any changes by posting the
                  revised Privacy Policy with a new effective date within the
                  App. Your continued use of the App after any changes indicates
                  your acceptance of the updated Privacy Policy.
                </p>

                <p></p>

                <p>9. Contact Us</p>

                <p></p>

                <p>
                  If you have questions or concerns about this Privacy Policy,
                  please contact us at info@meraplace.com.
                </p>

                <p></p>

                <p>
                  By using the App, you acknowledge that you have read and
                  understood this Privacy Policy and agree to its terms and
                  conditions.
                </p>
              </p>
            </div>
          </div>
        </div>
        <BoxAnimation />
      </div>
      <Footer />
    </>
  );
};
