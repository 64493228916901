import React from "react";
import { BoxAnimation } from "../components/BoxAnimation/BoxAnimation";
import { Navbar } from "../components/Header/Navbar";
import { Footer } from "../components/Footer/Footer";

export const RefundPolicy = () => {
  return (
    <>
      <div className="container-fluid   cardBg">
        <Navbar />
        <div className="col-md-10 mx-auto">
          <div className="card border-0 bg-transparent mx-auto">
            <div
              className="card-body"
              style={{ marginTop: "10rem", marginBottom: "6rem" }}
            >
              <h1 className="card-title animate-reveal animate-first MainHeadings text-center">
                Cancellation and Refund Policy
              </h1>

              <p className="card-text animate-reveal animate-second SubHeadings  mt-4">
                <u><p>Cancellation Policy:</p></u>
                <p>1.Subscription Cancellation:</p>
                <p>
                  Customers may cancel their subscription at any time during
                  their subscription period. To cancel the subscription, users
                  can log into their account on our website or mobile
                  application and follow the cancellation instructions provided.
                </p>
                <p>2.Cancellation Deadline:</p>
                <p>
                  Customers have the flexibility to cancel their subscription at
                  any time during the subscription period. We do not engage in
                  auto-renewal of subscriptions once the subscription period
                  ends; services will terminate with the conclusion of the
                  subscription period. Users are required to manually renew
                  their subscription before or after its expiry to continue
                  accessing the services.
                </p>
                <p>3.Effect of Cancellation:</p>
                <p>
                  Cancellation of the subscription will result in the immediate
                  termination of access to the provided services.
                </p>
                <u><p>Refund Policy:</p></u>
                <p>
                  1.Eligibility for Refund:
                  <br />
                  Purchases are non-refundable. No refund will be granted for
                  cancellations initiated by the customer during the
                  subscription period.
                </p>
                <p>
                  2. The subscriptions or add-ons purchased is yours for the
                  length of the subscription, even if you decide to cancel it.
                </p>
                <p>
                  3.We offer a proportionate refund under following
                  circumstances:
                </p>
                <p>
                  4.If the service remains non-functional continuously for more
                  than 48 hours, wherein the refund will be issued only for the
                  period during which the service remained non-functional;
                </p>
                <p>If we terminate, close or shut down of our services.</p>
                <p>
                  5.Refunds will not be provided if customers are unable to
                  access services due to their use of devices or software that
                  do not support some or all of our services, or because of
                  faults or malfunctions with their own devices. Additionally,
                  refunds will not be issued if access issues arise due to
                  customers' own account-related reasons.
                </p>
                <p>
                  6. No refund will be granted for cancellations initiated by
                  the customer during the subscription period.
                </p>
                <p>7. Refund Procedure:</p>
                <p>
                  8.If a customer requests a refund due to service
                  non-functionality, they must promptly contact our customer
                  support team within 12 hours via email at
                  [&bull;]meraplacehkg@gmail.com or through the designated
                  support channel for issue resolution. Should we be unable to
                  resolve the matter within 48 hours, the user can then submit
                  proof of the service's non-functionality. Upon receiving the
                  refund request, our dedicated team will verify the claim. If
                  the refund request is approved, a proportionate refund will be
                  provided for the period during which the services remained
                  non-operational.
                </p>
                <p>
                  9.In the event of our termination, closure, or shutdown of
                  services, we will issue a proportionate refund for the
                  remaining subscription period post deduction of administration
                  charges.
                </p>
                <u><p>Refund Approval:</p></u>
                <p>
                  Refunds are granted solely at our discretion and will be
                  assessed on a case-by-case basis. We reserve the right to deny
                  refund requests that do not meet the eligibility criteria or
                  fail to comply with the terms outlined in this policy.
                </p>
                <u><p>Refund Processing:</p></u>
                <p>
                  Refund claims will be processed with 15 working days. In case
                  of approved refund, points equivalent the amount of refund
                  will be credited to customers wallet balance on our
                  website/mobile application. The wallet credits can be used
                  against purchases made on our website only like for availing
                  any ad-hoc services, upgrading or renewal existing
                  subscription.
                </p>
                <p>
                  <br />
                  If customer have unused points in their wallet upon expiration
                  or cancellation of the subscription, they can make request for
                  withdrawal after the end of the user account
                  retention/deletion guidelines of the Company. These withdrawal
                  requests will be processed within 15 working days. Upon
                  approval, amount equivalent to the unused points will be
                  credited to the source account or any other designated account
                  in accordance with the Company's guidelines.
                </p>
                
                <u> <p>
                  <br />
                  Modification of Policy:
                </p></u>
               
                <p>
                  We reserve the right to modify or update this cancellation and
                  refund policy at any time without prior notice. Any changes
                  will be effective immediately upon posting the revised policy
                  on our website or mobile application.
                </p>
                <p>
                  By continuing to use our services after any amendments to this
                  policy, users acknowledge and agree to the updated terms.
                </p>
                <p>
                  This cancellation and refund policy is intended to outline the
                  guidelines and procedures related to subscription
                  cancellations and refund requests for our services. If you
                  have any further questions or require clarification, please
                  contact our customer support team.
                </p>
              </p>
            </div>
          </div>
        </div>
        <BoxAnimation />
      </div>
      <Footer />
    </>
  );
};
