import React from 'react'
import { Navbar } from '../Header/Navbar'
import rh from '../../media/Hitesh.jpg'
import r1 from '../../media/R1.jpeg'
import { BsTwitter } from 'react-icons/bs'
import ourTeam from '../../media/OurTeam.png'
import { BoxAnimation } from '../BoxAnimation/BoxAnimation'

export const OurTeam = () => {
    return (
        <>
            <div className='container-fluid card-bg'>
                <Navbar />
                <div className='row'>
                    <div className='col-md-6'>
                        <div className="card bg-transparent border-0 animate-reveal animate-first" style={{ marginTop:'8rem',marginBottom:'6rem' }}>
                            <div className="card-body" style={{ marginTop:'16rem'}}>
                                <h1 className='MainHeadings '>
                                Our Team
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="card  border-0 bg-transparent" style={{ marginTop: '10rem', marginBottom: '6rem' }}>
                            <div className='col-md-10 mx-auto floating'>
                                <img src={ourTeam} className="card-img-top animate-reveal animate-first" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='card bg-transparent border-0 text-center mt-4'>
                <div className='card-body'>
                    <h2 className='.InnerMianHaddings'>Leadership Team</h2>
                </div>
            </div>

            <div className='col-md-10 mx-auto'>
                <div className='card card-Style bg-transparent' >
                    <div className='row p-4 mt-4'>
                        <div className='col-md-4 '>
                            <div className="card__collection  clear-fix" >
                                <div className="cards cards--three ">
                                    <img src={rh} className="img-responsive" alt="" />
                                    <span className="cards--three__rect-1">
                                        <span className="shadow-1"></span>
                                        <p className='mt-2'>HITESH KUMAR</p>

                                    </span>
                                    <span className="cards--three__rect-2">
                                        <span className="shadow-2"></span>
                                    </span>
                                    <span className="cards--three__circle"></span>
                                    <div className="cards--three__list">
                                        <li><BsTwitter size={40} /></li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='card border-0 bg-transparent'>
                                <div className='card-body SubHeadingsBlack'>
                                    <h3><strong>FOUNDER & CEO</strong></h3>
                                    <p>
                                        Hitesh is the Founder and C.E.O of Protecons Solutions Private Limited with more than 15 years of expertise in Global IT Industry within P&C Insurance domain. He is known for his hands on techno functional experience in managing and leading large Application Services and Transformation programs in distributed Agile, waterfall methodologies while working in Onshore and Offshore delivery models.

                                        His passion is to work with people and build successful teams driven by passion and good will. After having built and led large capabilities in Business Analysis, Domain and Guidewire, he started the journey of Protecons to build a workforce of ProtecoNiN - Niche Guidewire experts. @Protecons People are @core - be it our employees, partners, service providers, clients, or end consumers. He aims to build a team of Happy stakeholders all around.

                                        @Protecons learning never stops and every team member is motivated to learn something new on daily basis. Hitesh himself is a Certified Guidewire Specialist, @CertCII, @DIPCII with Diploma in Insurance, @DipClaims with Diploma in Claims and pursuing his Advance Diploma in Insurance with The Chartered Insurance Institute, UK.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='card card-Style mt-5 bg-transparent' >
                    <div className='row p-4 mt-4'>
                        <div className='col-md-8 '>
                            <div className='card bg-transparent border-0'>
                                <div className='card-body SubHeadingsBlack'>
                                    <h3><strong>BUSINESS ADVISORY</strong></h3>
                                    <p>
                                        Richard Eager has approximately 35 years’ experience in the IT Services industry. This includes 12 years’ experience with global sourcing and delivery models.

                                        During his career Richard has specialized in the insurance and related financial services sectors for 25 years working in Australia, South East Asia and U.K./Europe. He has held various business development and executive leadership positions – particularly serving as Vice President, Financial Services for Computer Sciences Corporation (now DXC) where he had responsibility for the development and implementation of several claims and policy management systems. More recently, Richard consulted to various fintech startups in London.

                                        Richard holds a Bachelor of Education from University of New England and has 3 adult children and two grandchildren.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="card__collection  clear-fix">
                                <div className="cards cards--three  ms-auto">
                                    <img src={r1} className="img-responsive" alt="" />
                                    <span className="cards--three__rect-1">
                                        <span className="shadow-1"></span>
                                        <p className='mt-2'>RICHARD EAGER</p>

                                    </span>
                                    <span className="cards--three__rect-2">
                                        <span className="shadow-2"></span>
                                    </span>
                                    <span className="cards--three__circle"></span>
                                    <div className="cards--three__list">

                                        <li><BsTwitter size={40} /></li>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 mx-auto mt-5 SubHeadingsBlack '>
                    <div className='p-4'>
                        <h3>Management Team</h3>
                        <p >Our management team specialize in Application Services and Transformation Program delivery in a
                            standalone and multi vendor programs withe Guidewire program
                            implementation experience across the globe.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first">
                                <div className="card-body">
                                    <h5 className="card-title"><strong>GW QUALITY ASSURANCE MANAGERS</strong></h5>
                                    10+ IT Years <br />
                                    5+ Management Years<br />
                                    4+ GW Years<br />
                                    4+ P&C Insurance Years<br />
                                    3+ GW Projects<br />
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first">
                                <div className="card-body">
                                    <h5 className="card-title"><strong>GW PROGRAM MANAGERS</strong></h5>
                                    12+ IT Years<br />
                                    6+ Management Years<br />
                                    3+ GW Years<br />
                                    5+ P&C Insurance Years<br />
                                    2+ GW Projects<br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className='col-md-12 mx-auto mt-5 SubHeadingsBlack '>
                    <div className='p-4'>
                        <h3>Technical Team</h3>
                        <p>Guidewire Professional and ACE Certified Configuration,
                            Integration, Data and Digital Experts.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first">
                                <div className="card-body">
                                    <h5 className="card-title"><strong>GW FUNCTIONAL ARCHITECTS</strong></h5>
                                    10+ IT Years <br />
                                    8+ GW Years<br />
                                    8+ P&C Insurance Years<br />
                                    5+ GW Projects<br />
                                </div>
                            </div>
                            <div className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first mt-4">
                                <div className="card-body p-0 mt-4 mb-4">
                                    <h5 className="card-title"><strong>GW TECHNICAL ARCHITECTS</strong></h5>
                                    8+ IT Years <br />
                                    5+ GW Years<br />
                                    5+ P&C Insurance Years<br />
                                    4+ GW Projects<br />
                                </div>
                            </div>


                        </div>

                        <div className="col-sm-6">
                            <div className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first">
                                <div className="card-body">
                                    <h5 className="card-title"><strong>GW SENIOR CONSULTANTS</strong></h5>
                                    6+ IT Years<br />
                                    3+ GW Years<br />
                                    3+ P&C Insurance Years<br />
                                    2+ GW Projects<br />
                                </div>
                            </div>
                            <div className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first mt-4">
                                <div className="card-body mt-3">
                                    <h5 className="card-title"><strong>GW CONSULTANTS</strong></h5>
                                    4+ IT Years<br />
                                    2+ GW Years<br />
                                    2+ P&C Insurance Years<br />
                                    1+ GW Projects<br />
                                </div>
                            </div>
                        </div>



                        <div className='col-md-12 mx-auto mt-5 SubHeadingsBlack'>
                            <div className='p-4'>
                                <h3>Functional Team</h3>
                                <p>Insurance Industry and Guidewire Specialist - Certified functional experts
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first">
                                        <div className="card-body">
                                            <h5 className="card-title"><strong>GW FUNCTIONAL ARCHITECTS</strong></h5>
                                            10+ IT Years<br />
                                            6+ GW Years<br />
                                            8+ P&C Insurance Years<br />
                                            4+ GW Projects<br />
                                        </div>
                                    </div>
                                    <div className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first mt-4">
                                        <div className="card-body">
                                            <h5 className="card-title"><strong>GW SENIOR BUSINESS CONSULTANTS</strong></h5>
                                            8+ IT Years<br />
                                            4+ GW Years<br />
                                            4+ P&C Insurance Years<br />
                                            3+ GW Projects<br />
                                        </div>
                                    </div>


                                </div>

                                <div className="col-sm-6">
                                    <div className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first">
                                        <div className="card-body">
                                            <h5 className="card-title"><strong>GW BUSINESS CONSULTANTS</strong></h5>
                                            4+ IT Years
                                            2+ GW Years<br />
                                            3+ P&C Insurance Years<br />
                                            2+ GW Projects<br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className='col-md-12 mx-auto mt-5 SubHeadingsBlack'>
                            <div className='p-4'>
                                <h3>Quality Team</h3>
                                <p>Guidewire Professionals with deep Manual and Automation Testing experience
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first">
                                        <div className="card-body">
                                            <h5 className="card-title"><strong>GW QUALITY ARCHITECTS</strong></h5>
                                            8+ IT Years<br />
                                            4+ GW Years<br />
                                            4+ P&C Insurance Years<br />
                                            3+ GW Projects<br />
                                        </div>
                                    </div>

                                    <div className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first mt-4">
                                        <div className="card-body">
                                            <h5 className="card-title"><strong>GW QUALITY LEADS</strong></h5>
                                            6+ IT Years<br />
                                            3+ GW Years<br />
                                            3+ P&C Insurance Years<br />
                                            2+ GW Projects<br />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first">
                                        <div className="card-body">
                                            <h5 className="card-title"><strong>GW SENIOR QUALITY ANALYSTS</strong></h5>
                                            4+ IT Years<br />
                                            2+ GW Years<br />
                                            2+ P&C Insurance Years<br />
                                            1+ GW Projects<br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BoxAnimation/>
        </>
    )
}
