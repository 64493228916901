import React from 'react'
import { Navbar } from '../components/Header/Navbar'
import { Footer } from '../components/Footer/Footer'
import { BoxAnimation } from '../components/BoxAnimation/BoxAnimation'
import EngGservices from '../media/engagedGservices.png'

export const Services2 = () => {
    return (
        <>
            <div className='container-fluid card-bg'>
                <Navbar />

                <div className='row'>
                    <div className='col-md-6'>
                        <div className="card bg-transparent border-0 animate-reveal animate-first" style={{ marginTop:'8rem',marginBottom:'6rem' }}>
                            <div className="card-body" style={{ marginTop:'16rem'}}>
                                <h1 className='MainHeadings '>
                                ENGAGED GUIDEWIRE SERVICES
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="card  border-0 bg-transparent" style={{ marginTop: '10rem', marginBottom: '6rem' }}>
                            <div className='col-md-10 mx-auto floating'>
                                <img src={EngGservices} className="card-img-top animate-reveal animate-first" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-md-10 mx-auto mt-5 '>
                <div className="card border-0 card-Style bg-transparent card1 animate-reveal animate-first">
                    <div className="card-body">
                        <h4 className="card-title text-center InnerMianHaddings"><strong>ENGAGED GUIDEWIRE SERVICES</strong></h4>
                        <h5 className="card-title SubHeadingsBlack">ITS HERE</h5>
                        <p className="card-text SubHeadingsBlack">
                        Do you want to relook at your GW projects IT spends . 
                        We are glad to announce our Engaged Guidewire Services which
                         typically brings 15%-20% Direct program costs savings along with further 5%-10% 
                        improved program cost savings for typical Guidewire Implementations and Support projects.
                        </p>
                        <p className="card-text SubHeadingsBlack">
                        Do not worry our Engaged Services Demo is free. Simply chose to arrange a demo and we will arrange back the same at the earliest.
                        </p>
                        <a href="#" className="btn btn-outline-warning">Arrange Demo</a>
                    </div>
                </div>
            </div>
            <BoxAnimation/>
            <div className='mt-5'>
            <Footer/>
            </div>
          
        </>
    )
}
