import React from 'react'
import { ContectUs } from '../components/ContactUs/ContectUs'

export const Contacts = () => {
  return (
    <>
    <ContectUs/>
    </>
  )
}
