import React from 'react'
import { OurTeam } from '../components/OurTeam/OurTeam'
import { Footer } from '../components/Footer/Footer'

export const OurTeamPg = () => {
  return (
    <>
        <div>
        <OurTeam/>
    </div>
    <div className='mt-4'>
      <Footer/>
    </div>
    </>

  )
}
