import React from 'react'
import { CardCareers } from '../components/CustomCardCareers/CardCareers'
import { Footer } from '../components/Footer/Footer'

export const Careers = () => {
  return (
    <>
    <CardCareers/>
    <div>
    <Footer/>
    </div>
    </>
  )
}
