import "./App.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Home } from "./Pages/Home";
import { Careers } from "./Pages/Careers";
import { Services } from "./Pages/Services";
import { Services2 } from "./Pages/Services2";
import { Protlabs } from "./Pages/Protlabs";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Contacts } from "./Pages/Contacts";
import { OurTeamPg } from "./Pages/OurTeamPg";
import { Mission } from "./Pages/Mission";
import "./global.scss";
import { TermsConditions } from "./Pages/TermsConditions";
import { PrivacyPolicy } from "./Pages/PrivacyPolicy";
import { RefundPolicy } from "./Pages/RefundPolicy";
import { UserDeactivate } from "./Pages/DeleteUser";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/careers" element={<Careers />} />
          ServiceComp
          <Route path="/services" element={<Services />} />
          <Route path="/services2" element={<Services2 />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/prolabs" element={<Protlabs />} />
          <Route path="/ourteam" element={<OurTeamPg />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/user-deactivate" element={<UserDeactivate />} />
        </Routes>
        <ToastContainer />
      </Router>
    </>
  );
}

export default App;
