import React from 'react'
import logo from '../../media/brand.png'
import baseLogo from "../../media/logo2.png"
import { MdLocationPin } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import { IoLogoWhatsapp } from 'react-icons/io'
import { BsLinkedin } from 'react-icons/bs'

export const Footer = () => {
    let footerStyle = {
        top: "100%",
        width: "100%",
    }


  
    return (
        <footer className='footerBg' style={footerStyle}>
            <div className='container-fluid SubHeadings'>
                <div className='row'>
                    <div className='col-md-3 mt-5'>
                        <div className='card bg-transparent border-0 mt-2'>
                            <img src={logo} alt="" className='img-fluid ' />
                        </div>
                    </div>
                    <div className='col-md-3  mt-4 mb-4'>
                        <div className="card bg-transparent border-0 mt-5 mx-auto"  style={{ width: "18rem" }}>
                            <div className="card-body ">
                                <h5 className="card-title"><MdLocationPin />Reg Off:</h5>
                                <p>B-2315, 12th Avenue, Gaur City 2, UP 201009 India</p>
                               
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 mx-auto  mt-4 mb-4'>
                        <div className="card bg-transparent border-0 mt-4" style={{ width: "15rem" }}>
                            <div className="card-body">
                                <ul className="list-group list-group-flush ">
                                    <li className="list-group-item  bg-transparent  footerLink" > <NavLink to='/' id="style-2" className="text-light " data-replace="Home" onClick={()=>window.scrollTo(0,0)}><span>Home</span></NavLink></li>
                                    <li className="list-group-item  bg-transparent footerLink"><NavLink to="/services" id="style-2" className="text-light" data-replace="Services" onClick={()=>window.scrollTo(0,0)}><span>Services</span></NavLink></li>
                                    <li className="list-group-item  bg-transparent footerLink"><NavLink to="/contacts" id="style-2" className="text-light" data-replace="Contact Us" onClick={()=>window.scrollTo(0,0)}><span>Contact Us</span></NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 mx-auto mt-4 mb-4'>
                        <div className="card bg-transparent border-0 mt-4" style={{ width: "15rem" }}>
                            <div className="card-body">
                                <ul className="list-group list-group-flush ">
                                    <li className="list-group-item  bg-transparent  footerLink" > <NavLink to='/terms-conditions' id="style-2" className="text-light " data-replace="Terms & Conditions" onClick={()=>window.scrollTo(0,0)}><span>Terms & Conditions</span></NavLink></li>
                                    <li className="list-group-item  bg-transparent footerLink"><NavLink to="/privacy-policy" id="style-2" className="text-light" data-replace="Privacy Policy" onClick={()=>window.scrollTo(0,0)}><span>Privacy Policy</span></NavLink></li>
                                    <li className="list-group-item  bg-transparent footerLink"><NavLink to="/refund-policy" id="style-2" className="text-light" data-replace="Refund Policy" onClick={()=>window.scrollTo(0,0)}><span>Refund Policy</span></NavLink></li>
                                   
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-dark text-light p-0' >
                <div className='d-flex p-0'>
                    <div className='mx-auto p-2 fs-cus-1'>
                        Powred by  <span>
                            <img  src={baseLogo} className='img-fluid' alt="#" style={{width:"100px"}}/>
                        </span>
                    </div>
                
                </div>
            </div>
        </footer>
    )
}
