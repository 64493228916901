import React from 'react'
import { ServiceComp } from '../components/ServiceComp/ServiceComp'
import { Footer } from '../components/Footer/Footer'

export const Services = () => {
  return (
    <div>
        <ServiceComp/>
        <div className='mt-5'>
          <Footer/>
        </div>
    </div>
  )
}
