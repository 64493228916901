import React from "react";
import { Navbar } from "../Header/Navbar";
import { Footer } from "../Footer/Footer";
import { GoLocation } from "react-icons/go";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { send } from "emailjs-com";
import { Modal } from "reactstrap";
import sendGif from "../../media/sendGif.gif";
import contactUsImage from "../../media/contactUs.png";
import { BoxAnimation } from "../BoxAnimation/BoxAnimation";

export const ContectUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [modal, setModal] = useState(false);

  const submit = (data) => {
    send("service_9pe1z8l", "template_9tr9aai", data, "vA3p1vefGQqE-Z3Ce")
      .then((response) => {
        console.log("SUCCESS", response.status, response.text);
        reset();
        setModal(true);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  return (
    <>
      <div className="container-fluid card-bg">
        <Navbar />
        <div className="row">
          <div className="col-md-6">
            <div
              className="card bg-transparent border-0 animate-reveal animate-first"
              style={{ marginTop: "8rem", marginBottom: "6rem" }}
            >
              <div className="card-body" style={{ marginTop: "16rem" }}>
                <h1 className="MainHeadings">Contact Us</h1>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="card  border-0 bg-transparent"
              style={{ marginTop: "8rem", marginBottom: "6rem" }}
            >
              <div className="col-md-10 mx-auto floating">
                <img
                  src={contactUsImage}
                  className="card-img-top animate-reveal animate-first"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-10 mt-5 mx-auto animate-reveal animate-first">
        <div className="card Formbg bg-transparent">
          <div className="card border-0 p-0 text-center  formHaderBG ">
            <div className="card-body SubHeadings">
              <h3 className="card-title">
                <strong>HOW CAN WE HELP YOU!</strong>
              </h3>
              <p className="card-text">
                <strong>
                  We would love to hear from you . Please drop us a line and we
                  will get back to you at the earliest.
                </strong>
              </p>
            </div>
          </div>

          <form
            className="row g-3 p-4 SubHeadingsBlack"
            onSubmit={handleSubmit(submit)}
          >
            <div className="col-md-6 ">
              <label for="inputEmail4" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control bg-transparent text-dark  "
                id="inputEmail4"
                {...register("name", { required: true })}
              />
              {errors.name?.type === "required" && (
                <p role="alert" className="text-danger">
                  *First name is required
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label for="inputPassword4" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control bg-transparent text-dark"
                id="inputPassword4"
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.email?.type === "required" && (
                <p role="aler" className="text-danger">
                  *email is required
                </p>
              )}
              {errors.email?.type === "pattern" && (
                <p role="aler" className="text-danger">
                  *invalid email
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label for="inputEmail4" className="form-label">
                Company Name
              </label>
              <input
                type="text"
                className="form-control bg-transparent text-dark"
                id="inputEmail4"
                {...register("companyName", { required: true })}
              />
              {errors.companyName?.type === "required" && (
                <p role="alert" className="text-danger">
                  *Company name is required
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label for="inputPassword4" className="form-label">
                Designation
              </label>
              <input
                type="text"
                className="form-control bg-transparent text-dark"
                id="inputPassword4"
                {...register("designation", { required: true })}
              />
              {errors.designation?.type === "required" && (
                <p role="alert" className="text-danger">
                  *Designation is required
                </p>
              )}
            </div>
            <label className="form-check-label" for="gridCheck">
              Let us know your interests
            </label>
            <div className="row mt-4 mx-auto">
              <div className="col-md-4">
                <div className="form-check ">
                  <label className="form-check-label" for="gridCheck">
                    FuseIt
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    value="FuseIt"
                    {...register("interests")}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-check mx-5">
                  <label className="form-check-label" for="gridCheck">
                    E-commerce
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    value="E-commerce"
                    {...register("interests")}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-check mx-5">
                  <label className="form-check-label" for="gridCheck">
                    Other
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    value="Other"
                    {...register("interests")}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 mt-4">
              <label for="exampleFormControlTextarea1" className="form-label">
                Message
              </label>
              <textarea
                className="form-control bg-transparent text-dark"
                id="exampleFormControlTextarea1"
                rows="4"
                {...register("message")}
              ></textarea>
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-warning">
                SEND MESSAGE
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="col-md-12 mx-aut0 mt-5 p-0 m-0">
        <div className="row p-0 m-0">
          <div className="col-md-6 mx-auto ">
            <div className="card bg-transparent mt-5 border-0 text-center">
              <div className="card-body mt-5">
                <div className="iconColor mb-3">
                  <GoLocation size="54" />
                </div>
                <h5 className="SubHeadingsBlack">
                  <strong>Our Address</strong>
                </h5>
                <h2 className="card-title InnerMianHaddings">
                  HKG MERAPLACE LIMITED
                </h2>
              </div>
            </div>

            <div>
              <div className="mt-4 text-center Sub-Sub-Hadding">
                <p>B-2315, 12th Avenue, Gaur City 2, UP 201009 India</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mx-auto">
            <div className="card border-0">
              <div className="card-body">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.2961756630416!2d77.41905337409374!3d28.62088418461204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cefca8ec9d3df%3A0x69049f2e5151840a!2sb%2C%202315%2C%2012th%20Ave%2C%20Gaur%20City%202%2C%20Ghaziabad%2C%20Uttar%20Pradesh%20201009!5e0!3m2!1sen!2sin!4v1700217656752!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BoxAnimation />
      <div className="mt-5">
        <Footer />
      </div>

      <Modal
        className="position-absolute top-50 start-50 translate-middle"
        size={200}
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <div className="card bg-transparent border-0">
          <div className="card-body">
            <img src={sendGif} alt="Sendgif" width={200} height={150} />
          </div>
        </div>
      </Modal>
    </>
  );
};
