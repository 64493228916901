import React from "react";
import { Navbar } from "../Header/Navbar";
import vectorImg1 from "../../media/img1.png";
import { CustomCardServices } from "../CustomCardServices/CustomCardServices";
import { Counter } from "../Counter/Counter";
import { BoxAnimation } from "../BoxAnimation/BoxAnimation";
import { Chart } from "../Charts/Chart";

export const ServiceComp = () => {
  return (
    <>
      <div className="container-fluid card-bg ">
        <Navbar />
        <div className="row p-0 m-0">
          <div className="col-md-8 mx-auto p-0 m-0">
            <div className="card bg-transparent border-0 mb-5">
              <div className="card-body " style={{ height: "35rem" }}>
                <div style={{ marginTop: "12rem" }}>
                  <h2 className="item-1 MainHeadings">OUR SERVICES </h2>
                  <h2 className="item-2 MainHeadings">
                    FUSEIT APP <br />{" "}
                    <p className="fs-6 MainHeadings_sub">
                      FuseIT is your ultimate <br />
                      for seamlessly managing <br />
                      bothpersonal and business <br />
                      tasks with unparalleled <br />
                      efficiency and organization. <br />
                      Whether you're a busy <br />
                      professional, an entrepreneur <br />
                      , a student, or a homemaker, <br />
                      FuseIT is designed to simplify <br />
                      your life and boost your <br />
                      productivity.
                    </p>
                  </h2>

                  <h2 className="item-3 MainHeadings">
                    E-COMMERCE <br />
                    SOLUTION <br />
                    <p className="fs-6 MainHeadings_sub">
                      E-commerce solutions refer
                      <br />
                      to a set of software and <br />
                      technology tools that help
                      <br />
                      businesses sell products <br />
                      and services online. <br />
                      These solutions typically <br />
                      include a website or online
                      <br />
                      storefront, a shopping cart,
                      <br />
                      payment processing systems, <br />
                      and inventory management tools.
                    </p>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mx-auto p-0 m-0">
            <div className="card  border-0 bg-transparent ">
              <div
                className="col-md-12  mx-auto floating imghide"
                style={{ marginTop: "9rem" }}
              >
                <img
                  src={vectorImg1}
                  className="card-img-top animate-reveal animate-first "
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="card bg-transparent border-0 mt-4 mb-4">
          <div className="card-body">
            <div className="container-fluid ">
              <div className="mx-5">
                <h1 className="InnerMianHaddings animate-reveal animate-first ">
                  Our Services
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomCardServices />
      {/* <Counter/>
            <Chart/> */}
      <BoxAnimation />
    </>
  );
};
