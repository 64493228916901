import React from "react";
import { ClientsCard } from "../ClientsCard/ClientsCard";
import inovation from "../../media/inovation.png";
import colab from "../../media/strongCollaboration.jpg";
import values from "../../media/values.jpg";

export const CardCrousel = () => {
  return (
    <>
      <div className="container mt-5 ">
        <div className="row p-0 m-0 ms-4">
          <div className="col-md-4">
              <div className="cards">
                <div className="image">
                  <img href="#" src={inovation} />
                </div>
                <div className="content">
                  <h4>Mission</h4>
                  <p className="fs-cus-2">
                    Our mission is to keep People @Core, enabling them and to
                    continuously deliver ‘The Good’ while remaining latest with
                    the e-commerce solutions and mobile applications. Our goal
                    is to have happy people all around be it our employees,
                    service providers, partners, clients or end consumers.
                  </p>
                </div>
              </div>
          </div>
          <div className="col-md-4 mx-auto">
              <div className="cards">
                <div className="image">
                  <img href="#" src={colab} />
                </div>
                <div className="content">
                  <h4>Vision</h4>
                  <p className="fs-cus-2">
                    Our Vision is to let our customer experience certainty
                    through our team of ProtecoNINs. We don’t just say it but we
                    do it. Our aim is to build a team of Niche in the Niche who
                    are driven by core human values focused on delivering the
                    client centric solutions.
                  </p>
                </div>
              </div>
            </div>
          {/* </div> */}
          <div className="col-md-4 mx-auto">
              <div className="cards card-neon-bg">
                <div className="image ">
                  <img href="#" src={values} />
                </div>
                <div className="content">
                  <h4>Values</h4>
                  <p className="fs-cus-2">
                    Our values are driven through the DNA which is "Defined,
                    Nurtured and Achieved" in our people and we live at every
                    moment. It is our DNA that allows us to continuously and
                    repeatedly do ‘The Good’ and help us achieve the vision.
                  </p>
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  );
};
