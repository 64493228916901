import React from 'react'
import { Navbar } from '../Header/Navbar'
import m1 from './../../media/misson.png'
import v1 from './../../media/vision.png'
import v2 from './../../media/values.png'
import aboutUsimg from '../../media/aboutUs.png' 
import { BoxAnimation } from '../BoxAnimation/BoxAnimation'

export const MissionValues = () => {
    return (
        <>
            <div className='container-fluid card-bg'>
                <Navbar />

                <div className='row'>
                    <div className='col-md-6'>
                        <div className="card bg-transparent border-0 animate-reveal animate-first" style={{ marginTop:'8rem',marginBottom:'6rem' }}>
                            <div className="card-body" style={{ marginTop:'16rem'}}>
                                <h1 className='MainHeadings '>
                                About Us
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="card  border-0 bg-transparent" style={{ marginTop: '4rem', marginBottom: '6rem' }}>
                            <div className='col-md-10 mx-auto floating'>
                                <img src={aboutUsimg} className="card-img-top animate-reveal animate-first" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-md-10 mx-auto mt-5 '>
                <div className='p-4 text-center'>
                    <h2 className='InnerMianHaddings'>DO GOOD BE GOOD</h2>
                </div>

                <div class="card border-0 card-Style bg-transparent card1 animate-reveal animate-first p-1">

                    <div className='row '>
                        <div className='col-md-4'>
                        <div className='card bg-transparent border-0 '>
                                <div class="card-body p-0">
                                    <img src={m1} height="75%" width="75%" />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div class="card-body mx-auto mt-5 SubHeadingsBlack">
                                <h3 >
                                   <strong> Mission</strong>
                                </h3>
                                <p>
                                    Our mission is to keep People @Core, enabling them and to continuously
                                    deliver ‘The Good’ while remaining latest with the e-commerce solutions and
                                    mobile applications. Our goal is to have happy people all around be it our employees, service providers, partners, clients or end consumers.
                                </p>
                            </div>
                        </div>
                    </div>


                </div>


                <div class="card border-0 card-Style bg-transparent card1 animate-reveal animate-first p-1 mt-4">
                    <div className='row'>

                        <div className='col-md-8'>
                            <div class="card-body mx-auto mt-5 SubHeadingsBlack">
                                <h3>
                                    <strong>Vision</strong>
                                </h3>
                                <p>
                                    Our Vision is to let our customer experience certainty through our team of ProtecoNINs. We don’t just say it but we do it. Our aim is to build a team of Niche in the Niche who are driven by core human values focused on delivering the client centric solutions.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                        <div className='card bg-transparent border-0 '>
                                <div class="card-body p-0">
                                    <img src={v1} height="75%" width="75%" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="card border-0 card-Style bg-transparent card1 animate-reveal animate-first p-1 mt-4" >
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='card bg-transparent border-0 '>
                                <div class="card-body p-0">
                                    <img src={v2} height="75%" width="75%" />
                                </div>
                            </div>

                        </div>
                        <div className='col-md-8'>
                            <div class="card-body mx-auto mt-5 SubHeadingsBlack">
                                <h3>
                                    <strong>Values</strong>
                                </h3>
                                <p>
                                    Our values are driven through the DNA which is "Defined, Nurtured and Achieved" in our people and we live at every moment. It is our DNA that allows us to continuously and repeatedly do ‘The Good’ and help us achieve the vision.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
<BoxAnimation/>
        </>


    )
}
