import React from "react";
import { BoxAnimation } from "../components/BoxAnimation/BoxAnimation";
import { Navbar } from "../components/Header/Navbar";
import { Footer } from "../components/Footer/Footer";

export const TermsConditions = () => {
  return (
    <>
      <div className="container-fluid   cardBg">
        <Navbar />
        <div className="col-md-10 mx-auto">
          <div className="card border-0 bg-transparent mx-auto">
            <div
              className="card-body"
              style={{ marginTop: "10rem", marginBottom: "6rem" }}
            >
              <h1 className="card-title animate-reveal animate-first MainHeadings text-center">
                Terms & Conditions
              </h1>

              <p className="card-text animate-reveal animate-second SubHeadings  mt-4">
                <p>
                  This document is an electronic record in terms of the
                  Information Technology Act, 2000 and rules thereunder as
                  applicable and various other relevant statutes. This
                  electronic record is generated by a computer system and does
                  not require any physical or digital signatures.
                </p>
                <p>&nbsp;</p>
                <p>
                  This is a legal and binding agreement between you, the user
                  (&ldquo;User&rdquo; or &ldquo;You&rdquo;) of the Services, as
                  defined below, and HKG Meraplace LLP (MERAPLACE), stating the
                  terms that govern your use of the Site or the Platform, as
                  defined below. By accepting these terms of use in any manner
                  or accessing this website, you consent, agree and undertake to
                  abide, be bound by and adhere to the terms of use and the
                  Privacy Policy accessible at{" "}
                  <a href="https://www.meraplace.com/terms-conditions/">
                    https://www.meraplace.com/terms-conditions/
                  </a>{" "}
                  and{" "}
                  <a href="https://www.meraplace.com/privacy-policy">
                    https://www.meraplace.com/privacy-policy
                  </a>{" "}
                  &nbsp;respectively, and any other terms which govern the Site
                  (together referred to as &ldquo;Terms of Use&rdquo; or
                  &ldquo;Terms&rdquo; or &ldquo;Terms and Conditions&rdquo;) and
                  if you do not agree to these terms of use, you are not
                  entitled to avail of / use the services and any use thereafter
                  shall be unauthorized.
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  <strong>Acceptance of Terms:</strong>
                </p>
                <p>
                  By accessing or using our website and/or Mobile Application,
                  you acknowledge that you have read, understood, and agreed to
                  be bound by these terms and conditions. If you do not agree
                  with any part of these terms, please refrain from using our
                  website or mobile application. You can accept the Terms by
                  checking a checkbox or clicking on a button indicating your
                  acceptance of the terms or by actually using the Services.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Description and access of Service</strong>
                </p>
                <p>
                  We provide an array of services via mobile and web
                  applications under the name FUSEIT (referred to as the
                  Service) for online collaboration, real-time communications
                  and management of business operations and others within the
                  organisation (&ldquo;Service&rdquo; or
                  &ldquo;Services&rdquo;). You may use the Services for your
                  internal business purpose in the organization that you
                  represent. You may connect to the Services using any Internet
                  browser supported by the Services and using the mobile apps
                  developed by us. You are responsible for obtaining access to
                  the Internet and the equipment necessary to use the Services.
                  You can create and edit content with your user account and the
                  information you create is shared with other members of your
                  organisation.
                </p>
                <p>
                  User need to sign up for a user account by providing all
                  required information in order to access or use the Services.
                  Organization representative who wish to use the Services for
                  corporate internal use, we recommend that you, and all other
                  users from your organization, provide your corporate contact
                  information to sign up for user accounts
                </p>
                <p>&nbsp;</p>
                <p>Users agree to:</p>
                <p>
                  provide true, accurate, current, and complete information
                  about yourself as prompted by the sign-up process; and
                </p>
                <p>
                  maintain and promptly update the information provided during
                  sign up to keep it true, accurate, current, and complete. If
                  you provide any information that is untrue, inaccurate,
                  outdated, or incomplete, or if MERAPLACE has reasonable
                  grounds to suspect that such information is untrue,
                  inaccurate, outdated, or incomplete, MERAPLACE may terminate
                  your user account and refuse current or future use of any or
                  all of the Services.
                </p>
                <p>
                  <strong>Use of Information</strong>: We respect your privacy
                  and handle all personal information in accordance with our
                  privacy policy. By providing your personal information, you
                  consent to its use for the purpose of providing you the
                  services offered by us and keeping you informed about our
                  organization's activities, events, and initiatives, unless you
                  opt out of such communications.
                </p>
                <p>
                  Your opting to use the Service indicates your acceptance of
                  the terms of the{" "}
                  <a href="https://meraplace.com/privacy-policy">
                    MERAPLACE Privacy Policy
                  </a>
                </p>
                <p>
                  The Service may include certain communications from MERAPLACE,
                  such as service announcements, administrative messages, app
                  notifications and feature updates. You understand that these
                  communications shall be considered part of using the Services.
                  We provide you the option of opting out from receiving
                  marketing updates from us. However, you will not be able to
                  opt-out from receiving service announcements and
                  administrative messages and app notifications.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Organization Accounts and Administrators</strong>
                </p>
                <p>
                  When you sign up for an account for your organization you may
                  specify one or more administrators to manage your
                  organisation&rsquo;s account. The administrators will have the
                  right to configure the Services based on your requirements and
                  manage end users in your organization account.
                </p>
                <p>You are responsible for</p>
                <ul>
                  <li>
                    ensuring confidentiality of your organization account
                    password,
                  </li>
                  <li>
                    appointing competent individuals as administrators for
                    managing your organization account, and
                  </li>
                  <li>
                    ensuring that all activities that occur in connection with
                    your organization account comply with this Agreement.
                  </li>
                </ul>
                <p>
                  You understand that MERAPLACE is not responsible for account
                  administration and internal management of the Services for
                  you.
                </p>
                <p>
                  You are responsible for taking necessary steps for ensuring
                  that your organization does not lose control of the
                  administrator accounts. You may specify a process to be
                  followed for recovering control in the event of such loss of
                  control of the administrator accounts by sending an email to
                  meraplacehkg@gmail.com, provided that the process is
                  acceptable to MERAPLACE. In the absence of any specified
                  administrator account recovery process, MERAPLACE may provide
                  control of an administrator account to an individual providing
                  proof satisfactory to MERAPLACE demonstrating authorization to
                  act on behalf of the organization. You agree not to hold
                  MERAPLACE liable for the consequences of any action taken by
                  MERAPLACE in good faith in this regard.
                </p>
                <p>
                  <strong>Intellectual Property</strong>: The content and
                  materials on our website and mobile application, including but
                  not limited to text, graphics, logos, images, and software,
                  are protected by intellectual property laws and are the
                  property of our organization or our licensors. You may not
                  reproduce, modify, distribute, or exploit any content without
                  prior written permission from us.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Restrictions on Use:</strong>
                </p>
                <p>
                  In addition to all other terms and conditions of this
                  Agreement, you shall not:
                </p>
                <ul>
                  <li>
                    transfer the Services or otherwise make it available to any
                    third party;
                  </li>
                  <li>
                    provide any service based on the Services without prior
                    written permission from MERAPLACE;
                  </li>
                  <li>
                    use the Services in any manner that could damage, disable,
                    overburden, impair or harm any server, network, computer
                    system, resource of MERAPLACE;
                  </li>
                  <li>
                    Violate any applicable local, state, national or
                    international law; and
                  </li>
                  <li>
                    Create a false identity to mislead any person as to the
                    identity or origin of any communication.
                  </li>
                </ul>
                <p>
                  <strong>Limitation of Liability:</strong> Our organization and
                  its representatives shall not be liable for any direct,
                  indirect, incidental, consequential, or punitive damages
                  arising from the use of our website and mobile application or
                  any content or services provided therein.
                </p>
                <p>
                  <strong>Third-Party Websites</strong>: Our website may contain
                  links to third-party websites for your convenience. We have no
                  control over the content, privacy policies, or practices of
                  these websites and disclaim any responsibility for them.
                  Accessing and using third-party websites is at your own risk.
                </p>
                <p>
                  <strong>Modification of Terms of Service: </strong>
                </p>
                <p>
                  We reserve the right to modify or update these terms and
                  conditions at any time. Any changes will be effective
                  immediately upon posting on our website. It is your
                  responsibility to review these terms periodically. You may
                  terminate your use of the Services by providing MERAPLACE
                  notice by email within 15-days of being notified of the
                  availability of the modified Terms, if the Terms are modified
                  in a manner that substantially affects your rights in
                  connection with use of the Services. In the event of such
                  termination, you will be entitled to prorated refund of the
                  unused portion of any prepaid fees. Your continued use of the
                  Service after the effective date of any change to the Terms
                  will be deemed to be your agreement to the modified Terms.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Complaints</strong>
                </p>
                <p>
                  If we receive a complaint from any person against you with
                  respect to your activities as part of use of the Services, we
                  will forward the complaint to the primary email address of
                  your user account. You must respond to the complainant
                  directly within 10 days of receiving the complaint forwarded
                  by us and copy MERAPLACE in the communication. If you do not
                  respond to the complainant within 10 days from the date of our
                  email to you, we may disclose your name and contact
                  information to the complainant for enabling the complainant to
                  take legal action against you. You understand that your
                  failure to respond to the forwarded complaint within the 10
                  days&rsquo; time limit will be construed as your consent to
                  disclosure of your name and contact information by MERAPLACE
                  to the complainant.
                </p>
              </p>
            </div>
          </div>
        </div>

        <BoxAnimation />
      </div>
      <Footer />
    </>
  );
};
