import api from "../api";

const checkUserExists = (login_phone) => api.post("Login", { login_phone });

const sendOtpForDeactivation = (login_phone) =>
  api.post("generateDeactiveteOTP", { login_phone });

const OTPVerficationSignUp = (type, login_phone, otp_or_pin) =>
  api.post("OTPVerficationSignUp", { type, login_phone, otp_or_pin });

const LoginService = {
  checkUserExists,
  sendOtpForDeactivation,
  OTPVerficationSignUp,
};

export default LoginService;
