import React from 'react'
import { Navbar } from '../Header/Navbar'
import career from '../../media/careers1.jpeg'
import {FaLightbulb} from 'react-icons/fa'
import { BoxAnimation } from '../BoxAnimation/BoxAnimation'


export const CardCareers = () => {
    return (
        <>
            <div className='container-fluid card-bg'>
                <Navbar/>
                <div className='row '>
                    <div className='col-md-6' style={{marginTop:'6rem'}}>
                        <div className="card border-0 bg-transparent mx-auto " >
                            <div className="card-body">
                                <h1 className="card-title mx-5 mb-4 MainHeadings">
                                    Careers
                                </h1>
                                <div className="card bg-transparent border-0  animate-reveal animate-first imgResponse" style={{ width: '18rem' }}>
                                    <img src={career} className="card-img-top neon-bg " alt="..." />
                                </div>
                            </div>
                            <div className="card-img-overlay box" >
                                <div className='card neon-bg innerCardbg border-0 text-white animate-reveal animate-second'>
                                    <div className='p-3'>
                                    <h4>Current Areas of Interest:</h4>
                                    <div className='d-flex'>
                                    <FaLightbulb className='mx-2 mt-2' size={20}/>
                                    <p className='SubHeadings'>Guidewire InsuranceSuite</p>
                                    </div>
                                    <div className='d-flex'>
                                    <FaLightbulb className='mx-2 mt-2' size={20}/>
                                    <p className='SubHeadings'>Digital transformation (App and web development)</p>
                                    </div>
                                    <div className='d-flex'>
                                    <FaLightbulb className='mx-2 mt-2' size={30}/>
                                    <p className='SubHeadings'>Digital transformation Social Entrepreneurship - Product ideation, Development</p>
                                    </div>
                                    </div>   
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-6' style={{marginTop:'6rem'}}>
                        <div className="card border-0 bg-transparent mx-auto" style={{ marginTop: '6rem', marginBottom: '6rem'}}>
                            <div className="card-body">
                                <h3 className="card-title animate-reveal animate-first SubHeadings">
                                    Protecons is now expanding its family in its own way. If you think your DNA matches with Protecons', then you are at the right place. Please reach out to us at below email Ids.
                                </h3>
                                <div className='d-flex animate-reveal animate-second'>
                                    <a href="mailto:hr@proteconsol.com" className=" card-link fw-light text-dark">hr@proteconsol.com</a>
                                    <a href="mailto:tanushree.garg@proteconsol.com" className="card-link fw-light text-dark">tanushree.garg@proteconsol.com</a>
                                </div>
                            </div>
                        </div>
                        <BoxAnimation/>
                        
                        <div className="card border-0 bg-transparent mx-auto" style={{ marginTop: '6rem', marginBottom: '6rem' }}>
                            <div className="card-body">
                                <h3 className="MainHeadings card-title animate-reveal animate-second">
                                EXPANSION ALERT
                                </h3>
                                <p className='SubHeadings animate-reveal animate-third'>
                                We believe everyone has that ONE unique thing to change the world once given the opportunity, 
                                thus we are not limited by fixed skills. If you have a zeal to do new things, if your area of
                                 interest matches to any of below and you are willing to learn and grow with the organization 
                                 then we are all set to start our conversations. Give us a buzz and know the family, family size
                                 , How is our kitchen (Operations), what we can offer (services, packages), how we treat our guests (customers) etc.
                                We are welcoming new family members (equal opportunity for everyone) as per our current family planning exercise.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           
        </>
        
    )
}
