import React from 'react'
import { Pie } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export const Chart = () => {
  return (
    <>
      <div className='col-md-10 mx-auto mt-5'>
        <div className='text-center'>
          <h2 className="card-text CounterHeading">ProtecoNINs Guidewire experience summary</h2>
        </div>
        <div class="row row-cols-1 row-cols-md-2 g-4 mt-2 ">
          {chartData.map((obj) => {
            return (
              <>
                <div class="col">
                  <div class="card bg-transparent  text-center neon-bg">
                    <div className='p-4'>
                      <Pie
                        data={obj}
                        height={300}
                        width={500}
                        options={{
                          maintainAspectRatio: false,
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </div>
                    <div className='text-center'>
                      <h5 className='SubHeadingsBlack'><strong>{obj.title}</strong></h5>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </>
  )
}

const chartData = [
  

  {
    labels: ['Data Architects', 'Technical Architects', 'Functional Architects', 'Quality Experts', 'Upgrade Experts', 'Migration Experts', 'Integration Expert'],
    datasets: [
      {
        data: [20, 20, 20, 20, 20, 20, 20],
        backgroundColor: [
          'hsl(215deg 70% 32%)',
          'hsl(182deg 100% 31%)',
          'hsl(20deg 80% 59%)',
          'hsl(236deg 25% 23%)',
          'hsl(1deg 66% 56%)',
          'hsl(0deg 0% 0%)',
          'hsl(197deg 76% 42%)',
        ],
        borderColor: [
          'white',
          'white',
          'white',
          'white',
          'white',
          'white',
          'white',
        ],
        borderWidth: 2,
      },
    ],
    title: 'GW WorkForce'
  },

  {
    labels: ['V6.0 (CC, PC, Digital)', 'V7.0 (CC, PC)', 'V8.0 (CC, PC, BC, Digital)', 'V9.0 (CC, PC, BC)', 'V10.0 (CC, PC, BC, Digital)', 'v4.0 (CC)', 'V6.0 (CC, PC, Digital)'],
    datasets: [
      {

        data: [20, 20, 20, 20, 20, 20, 20],
        backgroundColor: [
          'hsl(215deg 70% 32%)',
          'hsl(182deg 100% 31%)',
          'hsl(20deg 80% 59%)',
          'hsl(236deg 25% 23%)',
          'hsl(1deg 66% 56%)',
          'hsl(0deg 0% 0%)',
          'hsl(197deg 76% 42%)',
        ],
        borderColor: [
          'white',
          'white',
          'white',
          'white',
          'white',
          'white',
          'white',
        ],
        borderWidth: 2,
      },
    ],
    title: 'GW Versions'
  },

  {
    labels: ['Application Development', 'System Integration', 'Post Go Live Support', 'Production Support',
      'Data Migration', 'Upgrades', 'Application Enhancements'],
    datasets: [
      {
        data: [20, 20, 20, 20, 20, 20, 20],
        backgroundColor: [
          'hsl(215deg 70% 32%)',
          'hsl(182deg 100% 31%)',
          'hsl(20deg 80% 59%)',
          'hsl(236deg 25% 23%)',
          'hsl(1deg 66% 56%)',
          'hsl(0deg 0% 0%)',
          'hsl(197deg 76% 42%)'
        ],
        borderColor: [
          'white',
          'white',
          'white',
          'white',
          'white',
          'white',
          'white',
        ],
        borderWidth: 2,
      },
    ],
    title: 'Type of Work'
  },

  {
    labels: ['Europe', 'United Kingdom', 'United States', 'Canada', 'APAC'],
    datasets: [
      {

        data: [20, 20, 20, 20, 20],
        backgroundColor: [
          'hsl(215deg 70% 32%)',
          'hsl(182deg 100% 31%)',
          'hsl(20deg 80% 59%)',
          'hsl(236deg 25% 23%)',
          'hsl(1deg 66% 56%)',
          // 'hsl(0,100%,50%)',
          // 'hsl(30,100%,50%)',
          // 'hsl(60,100%,50%)',
          // 'hsl(120,100%,50%)',
          // 'hsl(240,100%,50%)',
          // 'hsl(270,100%,50%)'
        ],
        borderColor: [
          'white',
          'white',
          'white',
          'white',
          'white',
        ],
        borderWidth: 2,
      },
    ],
    title: 'Geographies'
  }

]
