import React from "react";
import qualityTesting from "../../media/qualitytesting.jpg";
import caseStudyBanner1 from "../../media/case-study-banner-1.jpg";
import caseStudyBanner2 from "../../media/case-study-banner-2.jpg";
import topAgency from "../../media/top-agency-banner.jpg";

export const CustomCardServices = () => {
  return (
    <>
      <div className="col-md-10 mx-auto">
        <div className="row row-cols-1 row-cols-md-2 g-4">
          <div className="col">
            <div className="card cardStyle">
              <img src={caseStudyBanner1} />
              <div className="info">
                <p>
                  FuseIT is your ultimate all-in-one mobile app for seamlessly
                  managing both personal and business tasks with unparalleled
                  efficiency and organization. Whether you're a busy
                  professional, an entrepreneur, a student, or a homemaker,
                  FuseIT is designed to simplify your life and boost your
                  productivity.
                </p>
              </div>
            </div>
            <div className="SubHeadingsBlack mt-4 text-center">
              <h2>FUSEIT APPLICATION</h2>
            </div>
          </div>
          <div className="col">
            <div className="card cardStyle">
              <img src={caseStudyBanner2} />
              <div className="info">
                <p>
                  E-commerce solutions refer to a set of software and technology
                  tools that help businesses sell products and services online.
                  These solutions typically include a website or online
                  storefront, a shopping cart, payment processing systems, and
                  inventory management tools.
                </p>
              </div>
            </div>
            <div className="SubHeadingsBlack mt-4 text-center">
              <h2>E-COMMERCE SOLUTION</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
