import React from "react";
import logo from "../../media/brand.png";
import { NavLink } from "react-router-dom";
import { useState } from "react";

export const Navbar = () => {
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else setColor(false);
  };

  window.addEventListener("scroll", changeColor);

  return (
    <>
      <nav
        className={
          color
            ? "navbar navbar-expand-lg  topFixed navBg "
            : "navbar navbar-expand-lg  navtranP topFixed"
        }
      >
        <div className="container-fluid headerFix ">
          <a className="navbar-brand p-0" href="/">
            <img src={logo} alt="" width="160" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto mt-3">
              <nav>
                <div className="d-flex">
                  <ul id="main">
                    <li>
                      <NavLink
                        className="nav-link p-0 active mx-3 "
                        aria-current="page"
                        to="/"
                      >
                        <p className="text-light text-center fw-normal navLinkHover ">
                          Home
                        </p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="nav-link p-0 active "
                        aria-current="page"
                        to="/services"
                      >
                        <p className="text-light text-center  fw-normal navLinkHover">
                          Services
                        </p>
                      </NavLink>
                    </li>
                    {/* <li><NavLink className="nav-link p-0 active " aria-current="page" to="/prolabs"><p className='text-light text-center fw-normal navLinkHover'>&copy; Pro (t) Labs</p></NavLink></li> */}
                    {/* <li><NavLink to="/careers" className="nav-link p-0 active text-decoration-none " aria-current="page"><p className='text-light text-center  fw-normal navLinkHover'>Careers</p></NavLink></li> */}

                    <li>
                      <NavLink
                        className="nav-link p-0 active "
                        aria-current="page"
                        to="/mission"
                      >
                        <p className="text-light text-center  fw-normal navLinkHover">
                          About Us
                        </p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="nav-link p-0 active text-decoration-none "
                        aria-current="page"
                        to="/contacts"
                      >
                        <p className="text-light text-center  fw-normal navLinkHover">
                          Contact Us
                        </p>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className="nav-link p-0 active text-decoration-none "
                        aria-current="page"
                        to="/user-deactivate"
                      >
                        <p className="text-light text-center  fw-normal navLinkHover">
                          Delete User
                        </p>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </nav>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
