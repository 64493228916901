import React from "react";
import { DeleteUser } from "../components/DeleteUser/DeleteUser";

export const UserDeactivate = () => {
  return (
    <>
      <DeleteUser />
    </>
  );
};
